@font-face {
  font-family: "Proto MRS";
  src: url(Proto\ ML.ttf);
}

@keyframes blink{
0%{opacity: 0;}
50%{opacity: .5;}
100%{opacity: 1;}
}

html::-webkit-scrollbar {
  display: none;
}

#root {
  background-color: #161616;
  font-family: 'Proto MRS';
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 95vh;
  overflow-y: hidden;
  background-color: #161616;
}

.home {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  user-select: none;
}

.up {
  height: 95vh;
}

.down {
  height: 100vh;
}

.info {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: #a4a4a4;
  width: 81vw;
  text-align: center;
  height: 50vh;
  margin: 0;
}


.about {
  height: 95vh;
  display: flex;
  justify-content: space-evenly;
  color: #a4a4a4;
  flex-direction: column;
  align-items: center;
}
.about div.container {
  width: 80vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container div {
  display: flex;
  flex-direction: column;
  width: 33%;
  align-items: center;
  height: 40vh;
  justify-content: center;
  font-size: 1 rem;
  text-align: center;
}

.values{
  font-size: 1.1rem;
}
.paragraph {
  width: 310px;
}

.values {
  text-align: left;
}

.about .tile,
.greeting {
  font-size: 2rem;
  color: #34de16;
}

.about .copy {
  bottom: 0 !important;
}

.copy {
  position: absolute;
  bottom: -100vh;
  padding-bottom: 30px;
}

.sticky {
  position: fixed;
  top: 0;
  z-index: 5;
}

.sticky li.right {
  position: fixed;
  right: 0;
  background-color: #161616;
}

.right .icon {
  fill: #161616;
  width: 32px;
  height: 32px;
  margin-top: 5px;
  margin-right: 5px;
  align-items: center;
}

.gear {
  height: 95vh;
  color: #34de16;
  width: 100vw;
  display: flex;
  flex-direction: row;
  /* align-content: center; */
  justify-content: center;
  align-items: center;
}

.gear input {
  color: #ffffffac;
  font-family: 'Proto MRS';
  font-size: 1.3rem;
  border-radius: 0px;
  padding: 10px;
}

.gear input::placeholder {
  color: #ffffffac;
  font-family: 'Proto MRS';
}

.gear input[type='text'],
textarea {
  background-color: #008500;
  border: none;
}

input[type='text']:focus {
  outline: none;
  background-color: #1664089d;
}

input[type='text']:focus::placeholder{
  color: #34de1671
}
input:-internal-autofill-selected{
  outline: none;
  background-color: #1664089d;
}

.gear label {
  font-size: 1.3rem;
}

.gear form {
  width: 50vw;
  height: 80%;
  display: flex;
  align-items: center;
}

.gear form .btn {
  width: 100%;
}

.gear ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
}

.settingspic {
  width: 500px;
}

.gear ul li {
  display: flex;
  flex-direction: column;
}

.bgvid {
  z-index: 0;
  position: absolute;
  height: 92vh;
  width: -webkit-fill-available;
  object-fit: cover;
  opacity: 0.4;
}

.btn {
  height: 50px;
  font-size: 1rem;
  border: solid 1.45px #34de16;
  background-color: #1616169d;
  color: #34de16;
  width: fit-content;
  padding-left: 16px;
  padding-right: 16px;
  font-family: 'Proto MRS';
  font-size: 1.1rem;
  text-transform: uppercase;
}


.btn:hover {
  background-color: #34de163d;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
  z-index: 2;
  position: absolute;
  left: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: no-preference) {
  /* .App-logo {
    animation: App-logo-spin infinite 20s linear;
  } */
}

header.App-header {
  background-color: #1616161c;
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: #34de16;
  z-index: 1;
}
ul.App-header {
  height: 8vh;
  width: 100vw;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  position: sticky;
  background-color: #161616;
}

li.right:hover {
  background-color: #34de16 !important;
}

ul.App-header a {
  color: #34de16;
  text-decoration: none;
  padding-left: 20px;
  padding-right: 20px;
}

ul.App-header a:hover {
  color: #000000 !important;
}
ul.App-header li:hover {
  background-color: #34de16;
}
ul.App-header li {
  height: inherit;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.title {
  z-index: 1;
  position: absolute;
  height: 90vh;
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1616161c;
  color: #ffffff;
  font-size: 3rem;
}

.title .desc {
  font-size: 1rem;
}

.form {
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #1616161c;
  height: 150px;
}

.form label {
  display: flex;
}

.url {
  transition: all ease 0.7s;
}


.form label input {
  font-size: 1.2rem;
  border-radius: 5px;
  width: 80vw;
  height: 30px;
  border-color: #196a0b;
  background-color: #1b720c51;
  color: #34de16;
  text-align: center;
  font-family: 'Proto MRS';
}

::placeholder {
  color: #34de16;
}

.App-link {
  color: #34de16;
}

.report {
  border-spacing: 0;
}

.message {
  overflow-y: scroll;
  text-align: left;
  font-size: 1.2rem;
  width: 100vw;
  display: flex;
  justify-content: center;
  height: 80vh;
}

.message ol{
  margin-top: 0;
}

.card {
  border: none;
  border-radius: 15px;
  color: #65bb65;
  margin-bottom: 10px;
  background-color: #0f3c2d;
  width: 70vw;
}

.card::marker {
  left: 100px;
}

.card h1,
h2 {
  margin: 0;
  text-align: center;
}
.card td {
  border: 1px solid #00000096;
  padding: 15px;
}

.message ol li span {
  color: #65bb65;
}

.message::-webkit-scrollbar {
  width: 1em;
}

.message::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.message::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.invInfo {
  display: flex;
  flex-direction: column;
  width: 100vw;
  justify-content: space-around;
  height: 15vh;
  align-items: center;
  font-size: 1.1rem;
  background-color: #00000086;
  color: #65bb65;
}

.invInfo .outer {
  display: flex;
  flex-direction: column;
  width: 63vw;
}

.invInfo .inner {
  display: flex;
  justify-content: left;
}

.inner span {
  width: 10vw;
  text-align: left;
  
}

.label {
  text-align: center;
}

.alerttitle {
  display: flex;
  justify-content: space-evenly;
  padding-left: 299px;
  padding-right: 300px;
}

.Medium {
  color: #d39b00;
}

.Low {
  color: #f3f576;
}

.High {
  color: #ff0000;
}

.Informational {
  color: #59a1ff;
}

.message.false {
  display: none;
}
.invInfo.false {
  display: none;
}

.form.true {
  display: none;
}

.loading.false {
  display: none;
}

.loading img {
  filter: hue-rotate(180deg);
  filter: blur(1px);
}

.navigation {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: #fff;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}

.brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  margin-left: 1rem;
}
.navigation-menu {
  margin-left: auto;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
}
.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}
.navigation-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
}

.cheese {
  width: 50px;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #283b8b;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}
.hamburger:hover {
  background-color: #2642af;
}


.Typewriter__RATHOLE{
  color:#34de16;
}

.Cursor__RATHOLE{
  color:#ffffffac;
  animation: blink 0.7s linear infinite;
}

.Typewriter__desc{
  color:#34de16;
}

.Cursor__desc{
  color:#ffffffac;
  animation: blink 0.7s linear infinite;
}




